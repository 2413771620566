import PropTypes from 'prop-types';
import { useMemo, useEffect, useState } from 'react';

// @mui
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider as MUIThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { deDE as dataGridDeDE } from '@mui/x-data-grid';
import { deDE as coreDeDE } from '@mui/material/locale';
import { deDE } from '@mui/x-date-pickers/locales';
// hooks
import useSettings from 'src/hooks/useSettings';
//
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';

// ----------------------------------------------------------------------

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

export default function ThemeProvider({ children }) {
  const { themeMode, themeDirection, onChangeColor, themeColors, changeThemeColor, changeThemePartner } = useSettings();
  const isLight = themeMode === 'light';
  const queryParameters = new URLSearchParams(window.location.search);
  const partnerParam = queryParameters.get('partner');
  const colorParam = queryParameters.get('color');

  useEffect(() => {
    onChangeColor(partnerParam, `#${colorParam}`);
  }, [partnerParam, colorParam]);

  const themeOptions = useMemo(
    () => ({
      palette: isLight ? palette.light : palette.dark,
      typography,
      breakpoints,
      shape: { borderRadius: 8 },
      direction: themeDirection,
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight ? customShadows.light : customShadows.dark,
      deDE, // x-date-pickers translations
      dataGridDeDE, // x-data-grid translations
      coreDeDE, // core translations
    }),
    [isLight, themeDirection]
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
