import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

// utils
import axios from '../utils/axios';
import useAuth from 'src/hooks/useAuth';
import useSocket from '../hooks/useSocket';

// ----------------------------------------------------------------------

const initialState = {
  thgCount: {
    2022: {},
    2023: {},
    2024: {},
    claim: {
      provision: {
        new: 0,
        requested: 0,
        bankReceived: 0,
        completed: 0,
      },
      client: {
        new: 0,
        requested: 0,
        bankReceived: 0,
        reclaim: 0,
        'reclaim-requested': 0,
        'reclaim-payback': 0,
        inkasso: 0,
        completed: 0,
      },
    },
    payment: {
      payout: 0,
      claim: 0,
    },
  },
};
const handlers = {
  LOAD_THG_COUNT: (state, action) => {
    const { data, year } = action.payload;
    const tempThgCount = state.thgCount;

    const claimCount = data.claim;
    const paymentCount = data.payment;
    tempThgCount['payment'] = paymentCount;
    tempThgCount['claim'] = claimCount;
    delete data.payment;
    delete data.claim;
    tempThgCount[year] = data;
    return {
      ...state,
      thgCount: tempThgCount,
    };
  },
  UPDATE_COUNT: (state, action) => {
    const { data, year } = action.payload;
    const tempThgCount = state.thgCount;
    const oldDataObject = tempThgCount[year];
    tempThgCount[year] = { ...oldDataObject, ...data };
    return {
      ...state,
      thgCount: tempThgCount,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const CountContext = createContext({
  ...initialState,

  countThg: () => Promise.resolve(),
  countThgYears: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

CountProvider.propTypes = {
  children: PropTypes.node,
};

function CountProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { user } = useAuth();

  const { socket } = useSocket();

  useEffect(() => {
    const wsPartner = user.role === 'admin' || user.role === 'supervisor' ? 'admin' : user.partnerId;
    const isFleetManager = user.role === 'fleetManager';
    const wsRoom = `recount-${isFleetManager ? wsPartner + '-fleet' : wsPartner}`;
    socket.emit('joinRoom', wsRoom);

    socket.on(`recount`, (wsData) => {
      const { countItem, year } = wsData;

      //  Update count

      dispatch({
        type: 'UPDATE_COUNT',
        payload: {
          data: countItem,
          year: year,
        },
      });
    });

    socket.on('disconnect', () => {});

    return () => {
      socket.emit('leaveRoom', `recount-${user.partnerId}`);
      socket.off('recount');
    };
  }, []);

  //  Initial count all thg data for sidebar
  useEffect(() => {
    countThg('2022');
    countThg('2023');
    countThg('2024');
  }, []);

  const countThg = async (year) => {
    try {
      dispatch({
        type: 'START_LOADING',
        payload: {},
      });
      const endpoint = `/v1/thg/count/${year}`;
      const response = await axios.get(endpoint);
      dispatch({
        type: 'LOAD_THG_COUNT',
        payload: {
          data: response.data,
          year: year,
        },
      });
      dispatch({
        type: 'STOP_LOADING',
        payload: {},
      });
    } catch (err) {
      console.error(err);
    }
  };
  const countThgYears = () => {
    // countThg('2022');
    countThg('2023');
    countThg('2024');
  };

  return (
    <CountContext.Provider
      value={{
        ...state,

        countThg,
        countThgYears,
      }}
    >
      {children}
    </CountContext.Provider>
  );
}

export { CountProvider, CountContext };
