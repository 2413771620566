import { TextField, Typography, Grid } from '@mui/material';
import { Box } from '@mui/system';

export const PayoutForm = ({
  error,
  setM1,
  m1,
  lxe,
  setLxe,
  n1,
  setN1,
  n2,
  setN2,
  n3,
  setN3,
  m3,
  setM3,
  setM1NotInstant,
  m1NotInstant,
  lxeNotInstant,
  setLxeNotInstant,
  n1NotInstant,
  setN1NotInstant,
  n2NotInstant,
  setN2NotInstant,
  n3NotInstant,
  setN3NotInstant,
  m3NotInstant,
  setM3NotInstant,
}) => (
  <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
    <Typography variant="h6">Auszahlungspreise (brutto)</Typography>

    <Grid container spacing={2}>
      <Grid item xs={12} md={6} lg={6}>
        <Box>
          <Typography sx={{ fontWeight: 600, mb: 2 }}>Sofortauszahlung</Typography>
          <TextField
            error={error.m1}
            type="number"
            value={m1}
            onChange={(e) => {
              setM1(e.target.value);
            }}
            label="Auszahlungspreis M1 SOFORT"
            name="juicifySellPrice.M1"
            fullWidth
          />
          <TextField
            error={error.lxe}
            type="number"
            value={lxe}
            onChange={(e) => {
              setLxe(e.target.value);
            }}
            label="Auszahlungspreis LXe SOFORT"
            name="juicifySellPrice.LXe"
            fullWidth
          />
          <TextField
            error={error.n1}
            type="number"
            value={n1}
            onChange={(e) => {
              setN1(e.target.value);
            }}
            label="Auszahlungspreis N1 SOFORT"
            name="juicifySellPrice.N1"
            fullWidth
          />
          <TextField
            error={error.n2}
            type="number"
            value={n2}
            onChange={(e) => {
              setN2(e.target.value);
            }}
            label="Auszahlungspreis N2 SOFORT"
            name="juicifySellPrice.N2"
            fullWidth
          />
          <TextField
            error={error.n3}
            type="number"
            value={n3}
            onChange={(e) => {
              setN3(e.target.value);
            }}
            label="Auszahlungspreis N3 SOFORT"
            name="juicifySellPrice.N3"
            fullWidth
          />
          <TextField
            error={error.m3}
            type="number"
            value={m3}
            onChange={(e) => {
              setM3(e.target.value);
            }}
            label="Auszahlungspreis M3 SOFORT"
            name="juicifySellPrice.M3"
            fullWidth
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <Box>
          {' '}
          <Typography sx={{ fontWeight: 600, mb: 2 }}>Auszahlung nach Bestätigung durch Umweltbundesamt</Typography>
          <TextField
            error={error.m1NotInstant}
            type="number"
            value={m1NotInstant}
            onChange={(e) => {
              setM1NotInstant(e.target.value);
            }}
            label="Auszahlungspreis M1"
            name="juicifySellPriceNotInstant.M1"
            fullWidth
          />
          <TextField
            error={error.lxeNotInstant}
            type="number"
            value={lxeNotInstant}
            onChange={(e) => {
              setLxeNotInstant(e.target.value);
            }}
            label="Auszahlungspreis LXe"
            name="juicifySellPriceNotInstant.LXe"
            fullWidth
          />
          <TextField
            error={error.n1NotInstant}
            type="number"
            value={n1NotInstant}
            onChange={(e) => {
              setN1NotInstant(e.target.value);
            }}
            label="Auszahlungspreis N1"
            name="juicifySellPriceNotInstant.N1"
            fullWidth
          />
          <TextField
            error={error.n2NotInstant}
            type="number"
            value={n2NotInstant}
            onChange={(e) => {
              setN2NotInstant(e.target.value);
            }}
            label="Auszahlungspreis N2"
            name="juicifySellPriceNotInstant.N2"
            fullWidth
          />
          <TextField
            error={error.n3NotInstant}
            type="number"
            value={n3NotInstant}
            onChange={(e) => {
              setN3NotInstant(e.target.value);
            }}
            label="Auszahlungspreis N3"
            name="juicifySellPriceNotInstant.N3"
            fullWidth
          />
          <TextField
            error={error.m3NotInstant}
            type="number"
            value={m3NotInstant}
            onChange={(e) => {
              setM3NotInstant(e.target.value);
            }}
            label="Auszahlungspreis M3"
            name="juicifySellPriceNotInstant.M3"
            fullWidth
          />
        </Box>
      </Grid>
    </Grid>
  </div>
);
