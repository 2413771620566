import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
import Image from '../Image';
import { HOST_API } from 'src/config';

// ----------------------------------------------------------------------

export default function Logo() {
  return (
    <RouterLink to="/">
      <Box sx={{ height: '50px', display: 'flex' }}>
        <Image disabledEffect visibleByDefault alt="Logo" src={`${HOST_API}/static/logo/thg-quotenportal_logo.svg`} />
      </Box>
    </RouterLink>
  );
}
