import { Checkbox } from '@mui/material';
import { useState } from 'react';
import useData from 'src/hooks/useData';
import { ChildContainer } from './ChildContainer';
import './index.css';

export const ChildrenSelection = ({ childPartners, setChildPartners }) => {
  const { partners } = useData();

  return (
    <div>
      <div className="childSelectionContainer">
        {childPartners.map((partner, index) => (
          <div className="selectionContainer" key={index}>
            <ChildContainer partner={partner} setChildPartners={setChildPartners} />
          </div>
        ))}
      </div>
      <div className="childSelectionContainer">
        {partners.data.map((partner, index) => (
          <div className="selectionContainer" key={index}>
            {partner.name}
            <Checkbox
              checked={childPartners.includes(partner.name)}
              onChange={(e) => {
                if (e.target.checked) {
                  setChildPartners((prev) => [...prev, partner.name]);
                } else {
                  setChildPartners((prev) => prev.filter((child) => child !== partner.name));
                }
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
