import axios from 'src/utils/axios';

const apiUrl = process.env.REACT_APP_HOST_API;

export async function sendCode(phoneNumber) {
  const result = await axios.post(`${apiUrl}/v1/verify/sendCode`, { phoneNumber });
  return result;
}

export async function checkCode(phoneNumber, code) {
  const result = await axios.post(`${apiUrl}/v1/verify/verifyCode`, { phoneNumber, code });

  return result.data;
}
