// @mui
import { styled } from '@mui/material/styles';
import { Grid, Container, Typography, Link } from '@mui/material';
// components
import Page from 'src/components/Page';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function GreenfactoryImpressum() {
  return (
    <Page title="Impressum" sx={{ display: 'flex', flexGrow: 1, margin: 'auto' }}>
      <RootStyle>
        <Container sx={{ mt: 15, mb: 10, position: 'relative' }}>
          <Typography variant="h3" sx={{ mb: 1 }}>
            Impressum
          </Typography>
          <Typography variant="h5" sx={{ mb: 5 }}>
            Angaben gemäß § 5 TMG
          </Typography>

          <Typography sx={{ mb: 2 }}>
            msg systems ag
            <br />
            Robert- Bürkle-Straße 1
            <br />
            85737 Ismaning/München
          </Typography>

          <Typography sx={{ mb: 2 }}>
            Telefon: +49 89 96101-0
            <br />
            Telefax: +49 89 96101-1113
          </Typography>

          <Typography sx={{ mb: 2 }}>
            e-mail: <Link href="mailto:info@msg.group">info@msg.group</Link>
          </Typography>

          <Typography sx={{ mb: 2 }}>
            Vorstand:
            <br />
            Dr. Jürgen Zehetmaier (Vorsitzender),
            <br />
            Dr. Andrea van Aubel, Dr. Stephan Frohnhoff, Rolf Kranz, <br />
            Karsten Redenius, Dr. Frank Schlottmann
          </Typography>

          <Typography sx={{ mb: 2 }}>
            Aufsichtsratvorsitzender:
            <br />
            Johann Zehetmaier
          </Typography>

          <Typography sx={{ mb: 2 }}>
            Handelsregister:
            <br />
            Amtsgericht München
            <br />
            HRB 140 149
          </Typography>
          <Typography sx={{ mb: 2 }}>
            USt- Identifikationsnummer:
            <br />
            DE 129 420 400
          </Typography>

          <Typography sx={{ mb: 2 }}>## Disclaimer & Copyright</Typography>

          <Typography sx={{ mb: 2 }}>
            Uneingeschränkte Nutzungsrechte aller Inhalte (wie Texte, Bilder, Design und Quellcode) für diese Website
            liegen ausschließlich beim genannten Medieninhaber. Eine nicht kommerzielle Nutzung in elektronischer oder
            gedruckter Form ist zulässig, sofern die Inhalte nicht verändert werden und einen Quellenverweis beinhalten.
            Die kommerzielle Verwendung oder Weitergabe ist nach schriftlicher Genehmigung der Rechteinhaber erlaubt.
            Der Inhaber dieser Seite übernimmt keine Verantwortung für die Inhalte anderer Homepages, die auf diesen
            Seiten verlinkt sind.
          </Typography>
        </Container>
      </RootStyle>
    </Page>
  );
}
