import { Navigate } from 'react-router-dom';

// hooks
import useAuth from 'src/hooks/useAuth';

import { PATH_DASHBOARD } from '../routes/paths';

export default function GuardDirectSubmit({ children }) {
  const { user } = useAuth();

  if (!user.viewRights.includes('direct_submit')) {
    return <Navigate to={PATH_DASHBOARD.root} />;
  }

  return <>{children}</>;
}
