import { useState } from 'react';
// @mui
import { alpha, useTheme } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Divider, Typography, Stack, MenuItem } from '@mui/material';
// components
import Iconify from 'src/components/Iconify';
import MenuPopover from 'src/components/MenuPopover';
import { IconButtonAnimate } from 'src/components/animate';
import useAuth from 'src/hooks/useAuth';
import { APP_VERSION } from 'src/config';

export default function AccountPopover() {
  const [open, setOpen] = useState(null);
  const { logout, user } = useAuth();
  const theme = useTheme();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  const handleLogout = () => {
    logout();
  };

  const roleTranslations = {
    manager: 'Manager',
    fleetManager: 'Flottenmanager',
    agent: 'Agent',
    admin: 'Administrator',
    validator: 'Validator',
    supervisor: 'Supervisor',
  };
  return (
    <>
      <IconButtonAnimate
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        <Iconify icon={'eva:people-fill'} width={20} height={20} />
      </IconButtonAnimate>
      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ fontSize: '12px', color: theme.palette.grey[600], pl: 3, py: 1 }}>Version {APP_VERSION}</Box>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {roleTranslations[user.role]}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Abmelden
        </MenuItem>
      </MenuPopover>
    </>
  );
}
