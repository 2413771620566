import { Box, Button, Modal, useTheme } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { BlockPicker } from 'react-color';
import useSettings from 'src/hooks/useSettings';
import useAuth from 'src/hooks/useAuth';

const style = {
  outline: 'none',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '10px',
  pt: 2,
  px: 4,
  pb: 3,
};
export const ColorPicker = ({ error, color, setColor, size }) => {
  const { changeThemeColor, changeThemePartner } = useSettings();
  const { user } = useAuth();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setColor(color);
  }, [color, open]);
  const handleSave = useCallback(() => {
    setOpen(false);
  }, [color, user]);
  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Box sx={{ width: 'max-content', ...style, paddingTop: '30px' }}>
          <BlockPicker
            color={color}
            onChange={(color) => {
              setColor(color.hex);
            }}
          />
          <Button onClick={handleSave} sx={{ marginTop: '20px', width: '100%' }} type="submit" variant="contained">
            speichern
          </Button>
        </Box>
      </Modal>
      <Box
        onClick={() => {
          setOpen(true);
        }}
        component="span"
        sx={{
          borderWidth: error ? '1px' : '2px',
          borderColor: error ? 'red' : 'darkgray',
          borderStyle: 'solid',
          cursor: 'pointer',
          width: `${size}px`,
          height: `${size}px`,
          borderRadius: '50%',
          backgroundColor: color,
          display: 'inline-block',
          verticalAlign: '-2px',
        }}
      />
    </>
  );
};
