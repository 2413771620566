import { useEffect, useMemo, useState } from 'react';
// @mui
import { Box, ClickAwayListener, Input, InputAdornment, Slide, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

// utils

import cssStyles from 'src/utils/cssStyles';
// components
import { IconButtonAnimate } from 'src/components/animate';
import DataTableSearch from './DataTableSearch';
import Iconify from 'src/components/Iconify';
import useData from 'src/hooks/useData';

// ----------------------------------------------------------------------

const SearchbarStyle = styled('div')(({ theme }) => ({
  ...cssStyles(theme).bgBlur(),
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',

  position: 'absolute',
  alignItems: 'center',

  boxShadow: theme.customShadows.z8,
  padding: theme.spacing(1, 3),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(2, 5),
  },
}));

// ----------------------------------------------------------------------

export default function Searchbar() {
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();
  const [searchString, setSearchString] = useState('');
  const [query, setQuery] = useState('');

  const { resetThgsSearch } = useData();
  const preview = searchString.length > 3;

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
    setSearchString('');
    setQuery('');
    resetThgsSearch();
  };

  const onChangeSearch = (event) => {
    const eventSearchString = event.target.value;

    setSearchString(eventSearchString);
    setQuery(eventSearchString.toLowerCase().trim());
  };

  const viewResults = (q) => {
    setOpen(false);
    setSearchString('');
    navigate(`/app/process/search/${q}`);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      viewResults(query);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!isOpen && (
          <IconButtonAnimate onClick={handleOpen}>
            <Iconify icon={'eva:search-fill'} width={20} height={20} />
          </IconButtonAnimate>
        )}

        <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
          <SearchbarStyle>
            <Box sx={{ display: 'flex' }}>
              <Input
                onKeyPress={handleKeyPress}
                autoFocus
                fullWidth
                disableUnderline
                value={searchString}
                onChange={onChangeSearch}
                placeholder="Vorgang suchen..."
                startAdornment={
                  <InputAdornment position="start">
                    <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                  </InputAdornment>
                }
                sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
              />
              <Button
                variant="contained"
                onClick={() => {
                  viewResults(query);
                }}
              >
                suchen
              </Button>
            </Box>
            {preview && (
              <Box sx={{ marginTop: '15px' }}>
                <DataTableSearch handleClose={handleClose} searchString={query} />
              </Box>
            )}
          </SearchbarStyle>
        </Slide>
      </div>
    </ClickAwayListener>
  );
}
