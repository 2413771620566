import { useEffect, useRef, useState } from 'react';
import { Card, Modal, Typography } from '@mui/material';
import './index.css';
import { HOST_API } from 'src/config';

export const LogoSelection = ({ name, feedback, partner, error, setErrorCount }) => {
  const initialImages = {};

  const [images, setImages] = useState(initialImages);

  const logoRef = useRef(null);
  const logoIconRef = useRef(null);
  const logoLightRef = useRef(null);
  const logoIconLightRef = useRef(null);

  const fileContainers = [
    {
      id: name.toLowerCase() + '_logo',
      fileType: 'image/*',
      name: name.toLowerCase() + '_logo',
      styles: {
        height: 100,
        width: 400,
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        borderRadius: '0px',
      },
      ref: logoRef,
      text: 'logo',
    },
    {
      text: 'logo icon',
      id: name.toLowerCase() + '_logo_icon',
      fileType: 'image/*',
      name: name.toLowerCase() + '_logo_icon',
      styles: {
        height: 100,
        width: 100,
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        borderRadius: '0px',
      },
      ref: logoIconRef,
    },
  ];

  const [imgLoads, setImgLoads] = useState({ 1: false, 2: false, 3: false, 4: false });

  useEffect(() => {
    setErrorCount(Object.values(imgLoads).filter((value) => value === true).length);
  }, [imgLoads]);

  const onImageChange = (id) => (event) => {
    if (!name) {
      return;
    }
    if (event.target.files && event.target.files[0]) {
      const img = URL.createObjectURL(event.target.files[0]);
      const ext = event.target.files[0].name.split('.').pop();
      setImages((prev) => ({ ...prev, [id]: { img, id: `${id}.${ext}` } }));
    }
  };

  useEffect(() => {
    feedback(Object.values(images));
  }, [images]);

  return (
    <div>
      <Typography sx={{ color: error && 'red', marginLeft: '10px' }}>
        {error ? 'Logos müssen festgelegt werden' : 'Logos festlegen'}
      </Typography>
      <div
        style={{
          display: 'flex',
          gap: '30px',
          overflow: 'scroll',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          scrollbarColor: 'transparent transparent',
        }}
        className="scroll"
      >
        {fileContainers.map((container, i) => {
          const imageSrc = images[container.id]?.img || `${HOST_API}/static/logo/${container.name}.png`;

          return (
            <div key={i}>
              <Card
                onClick={() => {
                  container.ref.current.click();
                }}
                sx={container.styles}
              >
                <input
                  style={{ display: 'none' }}
                  accept={container.fileType}
                  ref={container.ref}
                  type="file"
                  onChange={onImageChange(container.id)}
                />
                {name && (
                  <img
                    key={imageSrc}
                    src={imageSrc}
                    alt={''}
                    onLoad={(e) => {
                      setImgLoads((prev) => ({ ...prev, [i + 1]: false }));
                    }}
                    onError={() => {
                      setImgLoads((prev) => ({ ...prev, [i + 1]: true }));
                    }}
                    style={{ width: '100%', height: '100%' }}
                  />
                )}
              </Card>
              <Typography variant="body2" sx={{ textAlign: 'center' }}>
                {container.text}
              </Typography>
            </div>
          );
        })}
      </div>
    </div>
  );
};
