import { Alert } from '@mui/material';
import { useEffect, useState } from 'react';
import { CustomPhoneInput } from './CustomPhoneInput';

export const PhoneNumberForm = ({ data, feedback }) => {
  const [value, setValue] = useState(data[0].client.phone);
  const [error, setError] = useState(false);

  useEffect(() => {
    feedback(value);
  }, [value]);

  return (
    <>
      <style>{`
      .container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-inline: 40px;
      }
        `}</style>
      <div className="container">
        <Alert severity="warning" sx={{ borderColor: 'red' }}>
          Ihre hinterlegte Telefonnummer lautet <b>{data[0].client.phone}</b>. Bevor wir mit der Vermarktung beginnen
          können, ist eine Verifizierung Ihrer Telefonnummer per SMS erforderlich. Sollte das nicht Ihre aktuelle Nummer
          oder keine Mobilfunknummer sein, bitten wir Sie, diese zu korrigieren.
        </Alert>

        <CustomPhoneInput
          error={error}
          onChange={(e) => {
            setValue(e);
          }}
          value={value}
          endAdornment={null}
        />
      </div>
    </>
  );
};
