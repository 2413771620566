import { Typography, Box } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import parse from 'html-react-parser';

export const InfoBox = ({ html, color }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        padding: '6px 16px',
        borderRadius: '4px',
        border: `1px solid ${color}`,
      }}
    >
      <ErrorIcon
        sx={{
          fontSize: '32px',
          opacity: 0.9,
          mr: '12px',
          mt: '15px',
          color: color,
        }}
      />
      <Typography sx={{ padding: '8px 0', fontSize: '14px' }}>{parse(html)}</Typography>
    </Box>
  );
};
