import axios from 'axios';
import qs from 'qs';
// config
import { HOST_API } from '../config';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
  paramsSerializer: {
    // serialize: (params, options)
    indexes: null,
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

//  REAUTH at 401 response because of not valid Accesstoken

// axiosInstance.interceptors.response.use(
//   (res) => {
//     return res;
//   },
//   async (err) => {
//     if (err.response) {
//       // Access Token was expired
//       if (err.response.status === 401) {
//         console.log("GET NEW TOKEN")
//         try {

//           const refreshToken = window.localStorage.getItem('refreshToken');

//           if (refreshToken && isValidToken(refreshToken)) {
//             const isStatusPage = window.location.pathname.startsWith('/status/');
//             const isVerifyPage = window.location.pathname.startsWith('/verify/');
//             const isPicsurePage = window.location.pathname.startsWith('/picsure/');
//             const isThgqpPage = window.location.pathname.startsWith('/thgqp/');

//             if (!isStatusPage && !isVerifyPage && !isPicsurePage && !isThgqpPage) {
//               const response = await axiosInstance.post('/v1/auth/refresh-tokens', {
//                 refreshToken,
//               });
//               const { tokens } = response.data;

//               const newAccessToken = tokens.access.token;
//               const newRefreshToken = tokens.refresh.token;

//               window.localStorage.removeItem('accessToken');
//               window.localStorage.removeItem('refreshToken');
//               window.localStorage.setItem('accessToken', newAccessToken);
//               window.localStorage.setItem('refreshToken', newRefreshToken);
//               setSession(newAccessToken);
//             }
//           }
//           return axiosInstance();
//         } catch (_error) {
//           return Promise.reject(_error);
//         }
//       }
//     }

//     return Promise.reject(err);
//   }
// );
export default axiosInstance;
