import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export const EmailSignatureSelector = ({ feedback, initialHtml }) => {
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [html, setHtml] = useState('');

  useEffect(() => {
    const contentBlock = htmlToDraft(decodeHtml(initialHtml));
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const initialEditorState = EditorState.createWithContent(contentState);
      setEditorState(initialEditorState);
      setHtml(initialHtml);
    }
  }, [initialHtml]);

  const handleEditorStateChange = (state) => {
    const rawContentState = convertToRaw(state.getCurrentContent());
    const markup = draftToHtml(rawContentState);

    setHtml(markup);
    setEditorState(state);
  };

  useEffect(() => {
    feedback(html);
  }, [html]);

  return (
    <Editor editorState={editorState} placeholder="Signatur festlegen" onEditorStateChange={handleEditorStateChange} />
  );
};

function decodeHtml(html) {
  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
}
