import PropTypes from 'prop-types';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// hooks
import useAuth from 'src/hooks/useAuth';
// pages
import Login from '../pages/auth/Login';
// components
import LoadingScreen from 'src/components/LoadingScreen';
import { PATH_DASHBOARD } from '../routes/paths';

// ----------------------------------------------------------------------

AuthGuardSubmissionPartner.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuardSubmissionPartner({ children }) {
  const { isAuthenticated, isInitialized, user } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    return <Login />;
  }

  if (user && user.role !== 'admin' && user.role !== 'submissionPartner') {
    return <Navigate to={PATH_DASHBOARD.root} />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
