import { deDE, enUS, frFR } from '@mui/material/locale';

import { PATH_DASHBOARD } from './routes/paths';

const pJson = require('../package.json');

// API
// ----------------------------------------------------------------------
const IS_GREENFACTORY_DOMAIN =
  window.location.hostname === 'greenfactory.msg.group' ||
  window.location.hostname === 'staging.greenfactory.msg.group';

export const HOST_API = IS_GREENFACTORY_DOMAIN
  ? process.env.REACT_APP_HOST_API_GREENFACTORY
  : process.env.REACT_APP_HOST_API;

export const HOST_DATAFORM = IS_GREENFACTORY_DOMAIN
  ? process.env.REACT_APP_HOST_DATAFORM_GREENFACTORY
  : process.env.REACT_APP_HOST_DATAFORM;

export const MAPBOX_API = process.env.REACT_APP_MAPBOX;
export const PLATFORM_PARTNER = process.env.REACT_APP_PLATFORM_PARTNER;
export const PLATFORM_HOST = process.env.REACT_APP_PLATFORM_HOST;
export const PLATFORM_HOST_GREENFACTORY = process.env.REACT_APP_PLATFORM_HOST_GREENFACTORY;
export const MAIN_THEME = process.env.REACT_APP_MAIN_THEME;
export const APP_VERSION = pJson.version;
export const ACTUAL_VAT = process.env.REACT_APP_ACTUAL_VAT;

// routes

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.app; // as '/dashboard/app'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you set settings.
// ----------------------------------------------------------------------

export const defaultSettings = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeColorPresets: MAIN_THEME,
  themeLayout: 'horizontal',
  themeStretch: false,
};

// MULTI LANGUAGES
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'Deutsch',
    value: 'de',
    systemValue: deDE,
    icon: '/assets/icons/flags/ic_flag_de.svg',
  },
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: '/assets/icons/flags/ic_flag_en.svg',
  },
  {
    label: 'French',
    value: 'fr',
    systemValue: frFR,
    icon: '/assets/icons/flags/ic_flag_fr.svg',
  },

  // {
  //   label: 'Vietnamese',
  //   value: 'vn',
  //   systemValue: viVN,
  //   icon: '/assets/icons/flags/ic_flag_vn.svg',
  // },
  // {
  //   label: 'Chinese',
  //   value: 'cn',
  //   systemValue: zhCN,
  //   icon: '/assets/icons/flags/ic_flag_cn.svg',
  // },
  // {
  //   label: 'Arabic (Sudan)',
  //   value: 'ar',
  //   systemValue: arSD,
  //   icon: '/assets/icons/flags/ic_flag_sa.svg',
  // },
];

export const defaultLang = allLangs[0]; // English
