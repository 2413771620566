import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import Image from './Image';
import useSettings from 'src/hooks/useSettings';
import useAuth from 'src/hooks/useAuth';
import { MAIN_THEME, HOST_API } from '../config';
// ----------------------------------------------------------------------
Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, justIcon = false, sx, statusPage, partnerId, isAuth }) {
  const theme = useTheme();

  const { themeColorPresets, themePartner } = useSettings();
  const partnerTheme = statusPage ? themeColorPresets : themePartner;
  const isLight = theme.palette.mode === 'light';
  const DEFAULT_THEME =
    window.location.hostname === 'greenfactory.msg.group' ||
    window.location.hostname === 'staging.greenfactory.msg.group'
      ? 'greenfactory'
      : MAIN_THEME;

  const { user } = useAuth();
  const showDefaultLogo = !user && !statusPage;

  const userPartner = user && user.partner.name;
  const partnerLogo = isAuth ? themePartner : !userPartner ? DEFAULT_THEME : userPartner;

  //  disabled link for loading screen - display just logo icon
  const logo = justIcon ? (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <Image
        disabledEffect
        visibleByDefault
        alt="Logo"
        style={{ filter: isLight ? 'none' : 'invert(0.1)' }}
        src={`${HOST_API}/static/logo/${partnerId?.toLowerCase() || partnerLogo?.toLowerCase()}${'_logo_icon.svg'}`}
      />
    </Box>
  ) : (
    <Box sx={{ ...sx }}>
      <img
        style={{ width: '100%', height: '100%', maxHeight: '50px', filter: isLight ? 'none' : 'invert(0.1)' }}
        alt="Logo"
        src={`${HOST_API}/static/logo/${partnerId?.toLowerCase() || partnerLogo?.toLowerCase()}${'_logo.svg'}`}
      />
      {showDefaultLogo && (
        <Box
          sx={{
            color: '#637381',
            textDecoration: 'none',
            fontSize: '12px',
            float: 'right',
            paddingTop: '5px',
          }}
        />
      )}
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }
  const html = statusPage ? <>{logo}</> : <RouterLink to="/">{logo}</RouterLink>;
  return html;
}
