import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
// components
import Logo from 'src/components/Logo';

// ----------------------------------------------------------------------
const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  marginLeft: '10px',
  padding: theme.spacing(1),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
  },
}));

// ----------------------------------------------------------------------

export default function LogoOnlyLayout({ partnerId }) {
  return (
    <>
      <HeaderStyle>
        <Logo statusPage partnerId={partnerId} />
      </HeaderStyle>
      <Outlet />
    </>
  );
}
