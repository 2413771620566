import Iconify from './Iconify';
import useSettings from 'src/hooks/useSettings';

// ----------------------------------------------------------------------

export default function VehicleIcon({ vehicleClass, size = 25, style = { verticalAlign: '-5', marginRight: '5px' } }) {
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';

  const iconMapping = {
    M1: (
      <Iconify
        icon={'mdi:car-hatchback'}
        width={size}
        height={size}
        color={isLight ? 'rgba(0,0,0,0.55)' : 'rgba(255,255,255,0.55)'}
        style={style}
      />
    ),
    LXe: (
      <Iconify
        icon={'mdi:motorbike'}
        width={size}
        height={size}
        color={isLight ? 'rgba(0,0,0,0.55)' : 'rgba(255,255,255,0.55)'}
        style={style}
      />
    ),
    N1: (
      <Iconify
        icon={'mdi:van-passenger'}
        width={size}
        height={size}
        color={isLight ? 'rgba(0,0,0,0.55)' : 'rgba(255,255,255,0.55)'}
        style={style}
      />
    ),
    N2: (
      <Iconify
        icon={'mdi:van-utility'}
        width={size}
        height={size}
        color={isLight ? 'rgba(0,0,0,0.55)' : 'rgba(255,255,255,0.55)'}
        style={style}
      />
    ),
    N3: (
      <Iconify
        icon={'mdi:truck'}
        width={size}
        height={size}
        color={isLight ? 'rgba(0,0,0,0.55)' : 'rgba(255,255,255,0.55)'}
        style={style}
      />
    ),
    M3: (
      <Iconify
        icon={'mdi:bus-side'}
        width={size}
        height={size}
        color={isLight ? 'rgba(0,0,0,0.55)' : 'rgba(255,255,255,0.55)'}
        style={style}
      />
    ),
  };
  return iconMapping[vehicleClass];
}
