// i18n
import './locales/i18n';

// scroll bar
import 'simplebar/src/simplebar.css';

// lightbox
import 'react-image-lightbox/style.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import NotistackProvider from './components/NotistackProvider';

// theme
import ThemeProvider from './theme';
//
import ThemeColorPresets from './components/ThemeColorPresets';
// redux
import { store, persistor } from './redux/store';
// contexts
import { SettingsProvider } from './contexts/SettingsContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';

import { AuthProvider } from './contexts/JWTContext';
import { SocketProvider } from './contexts/SocketContext';

//
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

// ----------------------------------------------------------------------

ReactDOM.render(
  <SettingsProvider>
    <ThemeProvider>
      <ThemeColorPresets>
        <NotistackProvider>
          <AuthProvider>
            <HelmetProvider>
              <ReduxProvider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <CollapseDrawerProvider>
                      <SocketProvider>
                        <BrowserRouter>
                          <App />
                        </BrowserRouter>
                      </SocketProvider>
                    </CollapseDrawerProvider>
                  </LocalizationProvider>
                </PersistGate>
              </ReduxProvider>
            </HelmetProvider>
          </AuthProvider>
        </NotistackProvider>
      </ThemeColorPresets>
    </ThemeProvider>
  </SettingsProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
