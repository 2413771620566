import { TextField, Typography } from '@mui/material';

export const BankDataForm = ({
  error,
  taxId,
  setTaxId,
  iban,
  handleIbanChange,
  paymentReceiver,
  setPaymentReceiver,
  creditorNumber,
  setCreditorNumber,
}) => (
  <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
    <Typography variant="h6">Bankdaten</Typography>

    <TextField
      error={error.taxId}
      value={taxId}
      onChange={(e) => {
        setTaxId(e.target.value);
      }}
      label="Steuernummer"
      name="taxId"
      fullWidth
    />
    <TextField
      error={error.iban}
      value={iban}
      onChange={(e) => {
        handleIbanChange(e);
      }}
      label="IBAN"
      name="bankData.iban"
      fullWidth
    />
    <TextField
      error={error.paymentReceiver}
      value={paymentReceiver}
      onChange={(e) => {
        setPaymentReceiver(e.target.value);
      }}
      label="Kontoinhaber"
      name="bankData.paymentReceiver"
      fullWidth
    />
    <TextField
      error={error.creditorNumber}
      value={creditorNumber}
      onChange={(e) => {
        setCreditorNumber(e.target.value);
      }}
      label="Kreditorennummer (optional)"
      name="creditorNumber"
      fullWidth
    />
  </div>
);
