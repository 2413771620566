// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
// components
import Page from 'src/components/Page';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function ThgqpImpressum() {
  return (
    <Page title="Impressum" sx={{ display: 'flex', flexGrow: 1, margin: 'auto' }}>
      <RootStyle>
        {/* <FaqsHero /> */}

        <Container sx={{ mt: 15, mb: 10, position: 'relative' }}>
          {/* <FaqsCategory /> */}
          <Typography variant="h3" sx={{ mb: 1 }}>
            Impressum
          </Typography>
          <Typography variant="h5" sx={{ mb: 5 }}>
            Angaben gemäß § 5 TMG
          </Typography>

          <Typography sx={{ mb: 2 }}>
            Picsure GmbH
            <br />
            c/o THG-Quotenportal
            <br />
            Heidenkampsweg 58
            <br />
            20097 Hamburg
          </Typography>
          <Typography sx={{ mb: 2 }}>
            Handelsregister: HRB 235272
            <br />
            Registergericht: Amtsgericht München
          </Typography>
          <Typography variant="h5" sx={{ mt: 1 }}>
            Vertreten durch:
          </Typography>
          <Typography sx={{ mb: 1 }}>Enrico Bolloni</Typography>

          <Typography variant="h5" sx={{ mt: 5 }}>
            Kontakt
          </Typography>
          <Typography sx={{ mb: 1 }}>
            Telefon: +49 (0)89 27781711
            <br />
            E-Mail: info@thg-quotenportal.de
          </Typography>

          <Typography variant="h5" sx={{ mt: 5 }}>
            Umsatzsteuer-ID
          </Typography>
          <Typography sx={{ mb: 1 }}>
            Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:
            <br />
            DE313291183
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            EU-Streitschlichtung
          </Typography>
          <Typography sx={{ mb: 1 }}>
            Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:{' '}
            <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">
              https://ec.europa.eu/consumers/odr/
            </a>
            .<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            Verbraucherstreitbeilegung / Universalschlichtungsstelle
          </Typography>
          <Typography sx={{ mb: 1 }}>
            Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
            Verbraucherschlichtungsstelle teilzunehmen.
          </Typography>
        </Container>
      </RootStyle>
    </Page>
  );
}
