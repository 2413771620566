import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// hooks
import useSettings from 'src/hooks/useSettings';
import useResponsive from 'src/hooks/useResponsive';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import useData from 'src/hooks/useData';
import useAuth from 'src/hooks/useAuth';

// config
import { HEADER, NAVBAR } from 'src/config';
//
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
import NavbarHorizontal from './navbar/NavbarHorizontal';

import FooterThgqp from 'src/components/thgqp/Footer';
import CompletePartner from 'src/components/completePartner';
import FooterGreenfactory from 'src/components/greenfactory/Footer';

// ----------------------------------------------------------------------

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 92,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    marginLeft: NAVBAR.DASHBOARD_WIDTH,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
  background: theme.palette.mode === 'light' ? '#FEFCFF' : theme.palette.grey[900],
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { loading } = useData();
  const { user } = useAuth();
  const partner = user.partner;
  const theme = useTheme();
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_LIGHTER = theme.palette.primary.lighter;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const PRIMARY_DARKER = theme.palette.primary.darker;

  const LayoutFooter =
    user?.partner?.platformPartner === 'Greenfactory' ? (
      <FooterGreenfactory isPlatform={true} />
    ) : (
      <FooterThgqp isPlatform={true} />
    );

  TopBarProgress.config({
    barColors: {
      0: PRIMARY_DARKER,
      0.25: PRIMARY_DARK,
      0.5: PRIMARY_MAIN,
      0.75: PRIMARY_LIGHTER,
      '1.0': PRIMARY_LIGHT,
    },
    shadowBlur: 2,
    barThickness: 1,
  });
  // TopBarProgress.config({
  //   barColors: {
  //     "0": PRIMARY_LIGHT,
  //     "0.25": PRIMARY_LIGHTER,
  //     "0.5": PRIMARY_MAIN,
  //     "0.75": PRIMARY_DARK,
  //     "1.0": PRIMARY_DARKER
  //   },
  //   shadowBlur: 5
  // });

  const { collapseClick, isCollapse } = useCollapseDrawer();

  const { themeLayout } = useSettings();

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  const verticalLayout = themeLayout === 'vertical';
  if (partner.incomplete) {
    return (
      <Box
        sx={{
          '& .MuiFormControl-fullWidth': { marginBottom: 1 },
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          minHeight: { lg: 1 },
          background: theme.palette.mode === 'light' ? '#FEFCFF' : theme.palette.grey[900],
        }}
      >
        <MainStyle collapseClick={collapseClick}>
          <Box>
            {' '}
            <CompletePartner partnerId={partner.name} partner={partner} setShowForm={() => {}} />
          </Box>
        </MainStyle>
        {LayoutFooter}
      </Box>
    );
  }
  if (verticalLayout) {
    return (
      <>
        <DashboardHeader onOpenSidebar={() => setOpen(true)} verticalLayout={verticalLayout} />

        {isDesktop ? (
          <NavbarHorizontal />
        ) : (
          <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        )}

        <Box
          component="main"
          sx={{
            px: { lg: 2 },
            pt: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
            },
            pb: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
            },
          }}
        >
          <Outlet />
        </Box>
      </>
    );
  }

  return (
    <Box
      sx={{
        '& .MuiFormControl-fullWidth': { marginBottom: 1 },
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        minHeight: { lg: 1 },
        background: theme.palette.mode === 'light' ? '#FEFCFF' : theme.palette.grey[900],
      }}
      // sx={{
      //   display: { lg: 'flex' },
      //   minHeight: { lg: 1 },
      // }}
    >
      {loading && <TopBarProgress />} <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} />
      <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle collapseClick={collapseClick}>
        <Outlet />
      </MainStyle>
      {/* <Box sx={{ flexGrow: 1 }} /> */}
      {/* <Box sx={{  width: '100%', position: 'fixed', bottom: '0px' }} > */}
      {LayoutFooter}
      {/* </Box> */}
    </Box>
  );
}
