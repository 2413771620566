import * as React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

//  MUI PRO VERSION LICENSE
import { LicenseInfo } from '@mui/x-license-pro';

//  Gloabl CSS
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

// routes
import Router from './routes';

// components
import Page500 from './pages/Page500';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';

import MotionLazyContainer from './components/animate/MotionLazyContainer';

import useAuth from './hooks/useAuth';

const isLocal = process.env.REACT_APP_ENVIRONMENT === 'local';
const pJson = require('../package.json');

// ----------------------------------------------------------------------
let ErrorBoundary = null;
if (!isLocal) {
  Bugsnag.start({
    plugins: [new BugsnagPluginReact()],
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
    appVersion: pJson.version,
    releaseStage: process.env.REACT_APP_ENVIRONMENT,
  });
  ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
}

export default function App() {
  const { user } = useAuth();

  if (user && !isLocal) {
    Bugsnag.setUser(user.id, user.email, user.name);
  }

  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE);

  if (isLocal) {
    return (
      <MotionLazyContainer>
        <ProgressBarStyle />

        {/* <Settings /> */}
        <ScrollToTop />
        <Router />
      </MotionLazyContainer>
    );
  }
  // Create the error boundary...
  return (
    <ErrorBoundary FallbackComponent={Page500}>
      <MotionLazyContainer>
        <ProgressBarStyle />

        {/* <Settings /> */}
        <ScrollToTop />
        <Router />
      </MotionLazyContainer>
    </ErrorBoundary>
  );
}
