import { useEffect } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, Container, Typography } from '@mui/material';
// components
import Page from 'src/components/Page';
import Logo from 'src/components/Logo';

// sections
import { LoginForm } from '../../sections/auth/login';

import useSettings from 'src/hooks/useSettings';
import { MAIN_THEME, PLATFORM_HOST } from 'src/config';

// ----------------------------------------------------------------------

const DEFAULT_THEME =
  window.location.hostname === 'greenfactory.msg.group' || window.location.hostname === 'staging.greenfactory.msg.group'
    ? 'greenfactory'
    : MAIN_THEME;

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
  background: '#FFF',
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  // padding: 'theme.spacing(3)',
  justifyContent: 'space-between',
  padding: '30px',
  paddingLeft: '16px',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: '30px',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { onChangeColor, onToggleMode } = useSettings();

  useEffect(() => {
    //  reset Theme for not logged in
    // onChangeColor(DEFAULT_THEME);
    // disable darkmode
    onToggleMode(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //  check if custom domain is requested- no admin portal for custom domain
  const isPlatformhost =
    window.location.hostname === PLATFORM_HOST || 'greenfactory.msg.group' || 'staging.greenfactory.msg.group';

  if (!isPlatformhost) {
    return <></>;
  }

  const IS_GREENFACTORY_DOMAIN =
    window.location.hostname === 'greenfactory.msg.group' ||
    window.location.hostname === 'staging.greenfactory.msg.group';

  return (
    <Page title="Anmelden">
      <RootStyle>
        <HeaderStyle>
          <Logo isAuth />

          {/* {smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
              Don’t have an account? {''}
              <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                Get started
              </Link>
            </Typography>
          )} */}
        </HeaderStyle>

        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  {IS_GREENFACTORY_DOMAIN ? 'GREENfactory' : 'THG-Quotenportal'}
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>Bitte Anmeldedaten eintragen.</Typography>
              </Box>
            </Stack>

            <LoginForm />

            {/* {!smUp && (
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                Don’t have an account?{' '}
                <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                  Get started
                </Link>
              </Typography>
            )} */}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
