import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
// @mui
import { styled, useTheme } from '@mui/material/styles';
//  import PreviewIcon from '@mui/icons-material/Preview';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import { Box, Typography } from '@mui/material';
import PreviewIcon from '@mui/icons-material/Preview';

import registrationExampleFront from './Zulassungsbescheinigung-I.1.jpg';

import Image from '../Image';
import RejectionFiles from './RejectionFiles';
import BlockContent from './BlockContent';
import NoPreview from './NoPreview';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(() => ({
  outline: 'none',
  overflow: 'hidden',
  position: 'relative',
  padding: '40px 8px',
  borderRadius: '8px',
  transition: 'padding 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
  // backgroundColor: "rgb(244, 246, 248)",
  // border: `1px dashed rgba(145, 158, 171, 0.32)`,
  boxShadow: 'rgba(0, 0, 0, 0.12)0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;`',
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
}));

const NoMaxWidthTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: '100%',
  },
});

// ----------------------------------------------------------------------

UploadSingleFile.propTypes = {
  error: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  helperText: PropTypes.node,
  sx: PropTypes.object,
};

export default function UploadSingleFile({ error = false, file, helperText, sx, label, ...other }) {
  const theme = useTheme();

  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    accept: {
      'image/*': ['.heic', '.pdf'],
      'application/*': ['.pdf'],
    },
    ...other,
  });
  const registrationImage = registrationExampleFront;
  return (
    <Box sx={{ position: 'relative', width: '100%', ...sx }}>
      <Box sx={{ position: 'absolute', top: '-35px', right: '0px', cursor: 'pointer' }}>
        <NoMaxWidthTooltip
          placement="bottom-end"
          enterTouchDelay={0}
          disableFocusListener
          title={
            <Image
              backgroundSize={'contain !important'}
              alt="Beispiel"
              src={registrationImage}
              sx={{
                width: { xs: '100%', md: '500px' },
                objectFit: 'contain',
              }}
            />
          }
        >
          <Box sx={{ display: 'flex', marginTop: '5px', marginRight: '5px', color: theme.palette.primary.main }}>
            <Typography>Beispiel</Typography> <PreviewIcon />
          </Box>
        </NoMaxWidthTooltip>
      </Box>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'rgb(255, 72, 66)',
            borderColor: 'rgb(255, 164, 141)',
            bgcolor: 'rgb(255, 231, 217)',
          }),
          ...(file && {
            padding: '12% 0',
            minHeight: '300px',
          }),
        }}
      >
        <input {...getInputProps()} />

        {file ? (
          <>
            {file.type === 'image/heic' || file.type === 'image/tiff' || file.type === 'application/pdf' ? (
              <NoPreview fileName={file.name} />
            ) : (
              <Image
                backgroundSize={'contain !important'}
                alt="file preview"
                src={typeof file === 'string' ? file : file.preview}
                sx={{
                  objectFit: 'contain',
                  top: 8,
                  left: 8,
                  borderRadius: 1,
                  position: 'absolute',
                  width: 'calc(100% - 16px)',
                  height: 'calc(100% - 16px)',
                }}
              />
            )}
          </>
        ) : (
          <BlockContent label={label} />
        )}
      </DropZoneStyle>

      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}

      {helperText && helperText}
    </Box>
  );
}
