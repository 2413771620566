import { Checkbox, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';

export const PlatformPartnerSelection = ({ setState, state, error }) => (
  <div>
    <FormControl fullWidth>
      <InputLabel>Platform Partner</InputLabel>
      <Select
        error={error}
        value={state}
        label="PlatformPartner"
        onChange={(e) => {
          setState(e.target.value);
        }}
      >
        <MenuItem value={'Greenfactory'}>Greenfactory</MenuItem>
        <MenuItem value={'THG-QUOTENPORTAL'}>THG-QUOTENPORTAL</MenuItem>
      </Select>
    </FormControl>
  </div>
);
