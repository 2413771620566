import { useContext } from 'react';
import { CountContext } from '../contexts/CountContext';

// ----------------------------------------------------------------------

const useCount = () => {
    const context = useContext(CountContext);
  
    if (!context) throw new Error('Count context must be use inside CountProvider');
  
    return context;
  };
  

export default useCount;
