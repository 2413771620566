import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import { Box, Button, Card, Divider, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import useData from 'src/hooks/useData';
import { isValidIBAN } from 'ibantools';
import { ColorPicker } from '../ColorPicker';
import { ChildrenSelection } from './ChildSelection';
import { EmailSignatureSelector } from './EmailSignature';
import { validateForm } from './FormValidation';
import { LogoSelection } from './LogoSelection';
import { PlatformPartnerSelection } from './PlatformPartner';
import { SubmissionPartnerSelection } from './SubmissionPartner';
import { ContactForm } from './ContactForm';
import { DataFormOptions } from './DataFormOptions';
import { BankDataForm } from './BankDataForm';
import { PayoutForm } from './PayoutForm';

const CompletePartner = ({ partnerId, setShowForm, partner, partners }) => {
  const { createPartner, updatePartner } = useData();
  const { enqueueSnackbar } = useSnackbar();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const [m1, setM1] = useState('');
  const [n1, setN1] = useState('');
  const [n2, setN2] = useState('');
  const [n3, setN3] = useState('');
  const [lxe, setLxe] = useState('');
  const [m3, setM3] = useState('');
  const [m1NotInstant, setM1NotInstant] = useState('');
  const [n1NotInstant, setN1NotInstant] = useState('');
  const [n2NotInstant, setN2NotInstant] = useState('');
  const [n3NotInstant, setN3NotInstant] = useState('');
  const [lxeNotInstant, setLxeNotInstant] = useState('');
  const [m3NotInstant, setM3NotInstant] = useState('');

  const [taxId, setTaxId] = useState('');
  const [paymentReceiver, setPaymentReceiver] = useState('');
  const [iban, setIban] = useState('');

  const [colorCode, setColorCode] = useState('');

  const [images, setImages] = useState([]);

  const [houseNumber, setHouseNumber] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [street, setStreet] = useState('');
  const [zip, setZip] = useState('');
  const [city, setCity] = useState('');
  const [domain, setDomain] = useState('');

  const [vehicleClasses, setVehicleClasses] = useState({
    M1: false,
    LXe: false,
    N1: false,
    N2: false,
    N3: false,
    M3: false,
  });
  const [payoutModes, setPayoutModes] = useState({
    instant: false,
    notInstant: false,
  });
  const [onlySecureIntegration, setOnlySecureIntegration] = useState(false);
  const [showFooter, setShowFooter] = useState(true);
  const [showHeader, setShowHeader] = useState(true);
  const [multipleVehicles, setMultipleVehicles] = useState(false);
  const [notificationEmail, setNotificationEmail] = useState('');
  const [phoneVerification, setPhoneVerification] = useState(false);
  const [childPartners, setChildPartners] = useState([]);
  const [platformPartner, setPlatformPartner] = useState('');
  const [submissionPartner, setSubmissionPartner] = useState('');
  const [showCharity, setShowCharity] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [noVerificationEmail, setNoVerificationEmail] = useState(false);
  const [equalPayout, setEqualPayout] = useState(false);
  const [deactivated, setDeactivated] = useState(false);
  const [showIntropage, setShowIntropage] = useState(false);

  const [creditorNumber, setCreditorNumber] = useState('');

  const [emailSignature, setEmailSignature] = useState('');

  const [imagesErrorCount, setImagesErrorCount] = useState(4);

  const [error, setError] = useState({
    name: false,
    email: false,
    phone: false,
    companyName: false,
    street: false,
    houseNumber: false,
    zip: false,
    city: false,
    domain: false,
    squareLogo: false,
    recLogo: false,
    emailSignature: false,
    colorCode: false,
    vehicleClasses: false,
    payoutModes: false,
    notificationEmail: false,
    phoneVerification: false,
    taxId: false,
    iban: false,
    payoutRecipient: false,
    m1: false,
    lxe: false,
    n1: false,
    n2: false,
    n3: false,
    m3: false,
    m1NotInstant: false,
    lxeNotInstant: false,
    n1NotInstant: false,
    n2NotInstant: false,
    n3NotInstant: false,
    m3NotInstant: false,
    squareBase64: false,
    recBase64: false,
    platformPartner: false,
    submissionPartner: false,
    logos: false,
    success: true,
  });

  useEffect(() => {
    if (partner) {
      setHouseNumber(partner.addressData.houseNumber);
      setStreet(partner.addressData.street);
      setCompanyName(partner.addressData.companyName);
      setZip(partner.addressData.zip);
      setCity(partner.addressData.city);
      setName(partner.name);
      setEmail(partner.email);
      setPhone(partner.phone);
      setColorCode(partner.colorCode);
      setNotificationEmail(partner.notificationEmail);
      setPhoneVerification(partner.phoneVerification);
      setIban(partner.bankData.iban);
      setM1(parseFloat(Math.round(partner.payoutPrices.M1)).toFixed(2));
      setN1(parseFloat(Math.round(partner.payoutPrices.N1)).toFixed(2));
      setN2(parseFloat(Math.round(partner.payoutPrices.N2)).toFixed(2));
      setN3(parseFloat(Math.round(partner.payoutPrices.N3)).toFixed(2));
      setLxe(parseFloat(Math.round(partner.payoutPrices.LXe)).toFixed(2));
      setM3(parseFloat(Math.round(partner.payoutPrices.M3)).toFixed(2));
      setM1NotInstant(parseFloat(Math.round(partner.payoutPricesNotInstant.M1)).toFixed(2));
      setN1NotInstant(parseFloat(Math.round(partner.payoutPricesNotInstant.N1)).toFixed(2));
      setN2NotInstant(parseFloat(Math.round(partner.payoutPricesNotInstant.N2)).toFixed(2));
      setN3NotInstant(parseFloat(Math.round(partner.payoutPricesNotInstant.N3)).toFixed(2));
      setLxeNotInstant(parseFloat(Math.round(partner.payoutPricesNotInstant.LXe)).toFixed(2));
      setM3NotInstant(parseFloat(Math.round(partner.payoutPricesNotInstant.M3)).toFixed(2));
      setDomain(partner.domain);
      setCreditorNumber(partner.creditorNumber);
      setTaxId(partner.taxId);
      setPaymentReceiver(partner.bankData.paymentReceiver);
      setVehicleClasses({
        M1: partner.dataformOptions.availableVehicleClasses.includes('M1'),
        LXe: partner.dataformOptions.availableVehicleClasses.includes('LXe'),
        N1: partner.dataformOptions.availableVehicleClasses.includes('N1'),
        N2: partner.dataformOptions.availableVehicleClasses.includes('N2'),
        N3: partner.dataformOptions.availableVehicleClasses.includes('N3'),
        M3: partner.dataformOptions.availableVehicleClasses.includes('M3'),
      });
      setPayoutModes({
        instant: partner.dataformOptions.availablePayoutModes.includes('instant'),
        notInstant: partner.dataformOptions.availablePayoutModes.includes('notInstant'),
      });
      setPlatformPartner(partner.platformPartner);
      setSubmissionPartner(partner.submissionPartner);
      setShowCharity(partner.dataformOptions.showCharity);
      setShowHelp(partner.dataformOptions.showHelp);
      setNoVerificationEmail(partner.dataformOptions.noVerificationEmail);
      setEqualPayout(partner.dataformOptions.equalPayout);
      setDeactivated(partner.dataformOptions.deactivated);
      setShowIntropage(partner.dataformOptions.showIntropage);
      setMultipleVehicles(partner.dataformOptions.multipleVehicles);
      setOnlySecureIntegration(partner.dataformOptions.onlySecureIntegration);
      setShowFooter(partner.dataformOptions.showFooter);
      setShowHeader(partner.dataformOptions.showHeader);
      setChildPartners(partner.childrenPartner);
      setEmailSignature(partner.emailSignature);
    }
  }, [partner]);

  useEffect(() => {
    if (!error.success) {
      const validation = validateForm({
        name,
        email,
        phone,
        m1,
        n1,
        n2,
        n3,
        lxe,
        m3,
        m1NotInstant,
        n1NotInstant,
        n2NotInstant,
        n3NotInstant,
        lxeNotInstant,
        m3NotInstant,
        taxId,
        paymentReceiver,
        iban,
        colorCode,
        houseNumber,
        street,
        companyName,
        zip,
        city,
        domain,
        vehicleClasses,
        payoutModes,
        onlySecureIntegration,
        showFooter,
        showHeader,
        multipleVehicles,
        notificationEmail,
        phoneVerification,
        childPartners,
        emailSignature,
        platformPartner,
        submissionPartner,
        logos: imagesErrorCount,
      });
      setError({ ...validation });
    }
  }, [
    childPartners,
    city,
    colorCode,
    domain,
    email,
    phone,
    emailSignature,
    houseNumber,
    iban,
    multipleVehicles,
    lxeNotInstant,
    m1NotInstant,
    m3NotInstant,
    n1NotInstant,
    n2NotInstant,
    n3NotInstant,
    name,
    notificationEmail,
    phoneVerification,
    onlySecureIntegration,
    paymentReceiver,
    platformPartner,
    submissionPartner,
    showFooter,
    showHeader,
    companyName,
    street,
    taxId,
    vehicleClasses,
    payoutModes,
    zip,
    images,
  ]);

  const handleSubmit = useCallback(async () => {
    const validation = validateForm({
      name,
      email,
      phone,
      m1,
      n1,
      n2,
      n3,
      lxe,
      m3,
      m1NotInstant,
      n1NotInstant,
      n2NotInstant,
      n3NotInstant,
      lxeNotInstant,
      m3NotInstant,
      taxId,
      paymentReceiver,
      iban,
      colorCode,
      companyName,
      houseNumber,
      street,
      zip,
      city,
      domain,
      vehicleClasses,
      payoutModes,
      onlySecureIntegration,
      showFooter,
      showHeader,
      multipleVehicles,
      notificationEmail,
      phoneVerification,
      childPartners,
      platformPartner,
      submissionPartner,
      emailSignature,
      logos: imagesErrorCount,
    });
    setError({ ...validation });
    if (validation.success) {
      const body = {
        platformPartner,
        submissionPartner,
        addressData: { companyName, houseNumber, street, zip, city },
        name,
        email,
        phone,
        payoutPrices: {
          M1: parseFloat(m1).toFixed(2) || 0,
          N1: parseFloat(n1).toFixed(2) || 0,
          N2: parseFloat(n2).toFixed(2) || 0,
          N3: parseFloat(n3).toFixed(2) || 0,
          LXe: parseFloat(lxe).toFixed(2) || 0,
          M3: parseFloat(m3).toFixed(2) || 0,
        },
        payoutPricesNotInstant: {
          M1: parseFloat(m1NotInstant).toFixed(2) || 0,
          N1: parseFloat(n1NotInstant).toFixed(2) || 0,
          N2: parseFloat(n2NotInstant).toFixed(2) || 0,
          N3: parseFloat(n3NotInstant).toFixed(2) || 0,
          LXe: parseFloat(lxeNotInstant).toFixed(2) || 0,
          M3: parseFloat(m3NotInstant).toFixed(2) || 0,
        },
        bankData: { paymentReceiver, iban },
        dataformOptions: {
          showCharity,
          showHelp,
          noVerificationEmail,
          equalPayout,
          deactivated,
          showIntropage,
          availableVehicleClasses: Object.keys(vehicleClasses)
            .filter((key) => vehicleClasses[key])
            .map((key) => key),
          availablePayoutModes: Object.keys(payoutModes)
            .filter((key) => payoutModes[key])
            .map((key) => key),
          multipleVehicles,
          showFooter,
          showHeader,
          onlySecureIntegration,
        },
        taxId,
        colorCode,
        domain,
        notificationEmail,
        phoneVerification,
        childrenPartner: childPartners,
        creditorNumber,
        emailSignature,
      };

      const formData = new FormData();

      await Promise.all(
        images.map(async (file) => {
          const response = await fetch(file.img);
          const blob = await response.blob();
          formData.append(file.id, new File([blob], file.id));
        })
      );

      const jsonBody = JSON.stringify(body);
      formData.append('data', jsonBody);

      if (partnerId) {
        const response = await updatePartner(partnerId, formData);
        if (response.status === 200) {
          enqueueSnackbar('Partner aktualisiert', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          });
        } else {
          enqueueSnackbar('Es ist ein Fehler aufgetreten', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          });
        }
      } else {
        const response = await createPartner(formData);
        if (response.status === 201) {
          enqueueSnackbar('Partner angelegt', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          });
        } else {
          enqueueSnackbar('Es ist ein Fehler aufgetreten', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          });
        }
      }
      setShowForm(false);
    }
  }, [
    imagesErrorCount,
    childPartners,
    city,
    colorCode,
    createPartner,
    creditorNumber,
    domain,
    email,
    phone,
    emailSignature,
    houseNumber,
    iban,
    images,
    multipleVehicles,
    lxe,
    m1,
    m3,
    n1,
    n2,
    n3,
    lxeNotInstant,
    m1NotInstant,
    m3NotInstant,
    n1NotInstant,
    n2NotInstant,
    n3NotInstant,
    name,
    notificationEmail,
    phoneVerification,
    onlySecureIntegration,
    partnerId,
    paymentReceiver,
    platformPartner,
    submissionPartner,
    setShowForm,
    showFooter,
    showHeader,
    street,
    companyName,
    taxId,
    updatePartner,
    vehicleClasses,
    payoutModes,
    showCharity,
    showHelp,
    noVerificationEmail,
    equalPayout,
    deactivated,
    showIntropage,
    zip,
  ]);

  const handleEmailChange = (event) => {
    const { value } = event.target;

    if (!value.includes(' ')) {
      setEmail(value);
    }
  };

  const handleVehicleClassChange = (event) => {
    setVehicleClasses((prevClasses) => ({
      ...prevClasses,
      [event.target.name]: event.target.checked,
    }));
  };
  const handlePayoutModeChange = (event) => {
    setPayoutModes((prevClasses) => ({
      ...prevClasses,
      [event.target.name]: event.target.checked,
    }));
  };

  const handleOnlySecureIntegrationChange = (event) => {
    setOnlySecureIntegration(event.target.checked);
  };

  const handleShowFooterChange = (event) => {
    setShowFooter(event.target.checked);
  };

  const handleShowHeaderChange = (event) => {
    setShowHeader(event.target.checked);
  };

  const handleIbanChange = (event) => {
    const rawText = event.target.value.toUpperCase().replace(/[^\dA-Z]/g, '');
    const formattedText = rawText.replace(/(.{4})/g, '$1 ').trim();

    setIban(formattedText);
  };

  return (
    <Box>
      <Card>
        <>
          <Box sx={{ p: 2 }}>
            <Typography>Bitte vervollständigen Sie Ihre Angaben um fortzufahren</Typography>
            <form onSubmit={handleSubmit}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <ContactForm
                  partner={partner}
                  error={error}
                  domain={domain}
                  setDomain={setDomain}
                  phone={phone}
                  setPhone={setPhone}
                  name={name}
                  setName={setName}
                  companyName={companyName}
                  setCompanyName={setCompanyName}
                  street={street}
                  setStreet={setStreet}
                  houseNumber={houseNumber}
                  setHouseNumber={setHouseNumber}
                  zip={zip}
                  setZip={setZip}
                  email={email}
                  handleEmailChange={handleEmailChange}
                  city={city}
                  setCity={setCity}
                />
                <PlatformPartnerSelection
                  error={error.platformPartner}
                  state={platformPartner}
                  setState={setPlatformPartner}
                />
                <SubmissionPartnerSelection
                  error={error.submissionPartner}
                  state={submissionPartner}
                  setState={setSubmissionPartner}
                />
              </div>{' '}
              <Divider sx={{ marginTop: '40px', marginBottom: '40px' }} />
              <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <Typography variant="h6">Child Partner</Typography>
                <ChildrenSelection
                  partners={partners}
                  childPartners={childPartners}
                  setChildPartners={setChildPartners}
                />
              </div>
              <Divider sx={{ marginTop: '40px', marginBottom: '40px' }} />
              <div style={{ display: 'flex', flexDirection: 'column', gap: '25px' }}>
                <Typography variant="h6">Darstellung</Typography>

                <Card sx={{ padding: '10px' }}>
                  <EmailSignatureSelector
                    feedback={(value) => {
                      setEmailSignature(value);
                    }}
                    initialHtml={partner ? partner.emailSignature : ''}
                  />
                </Card>
                <LogoSelection
                  error={error.logos}
                  name={name}
                  feedback={(value) => {
                    setImages([...value]);
                  }}
                  partner={partner}
                  setErrorCount={setImagesErrorCount}
                />

                <div style={{ marginLeft: '15px', display: 'flex', gap: '10px', alignItems: 'center' }}>
                  <Typography sx={{ color: error.colorCode ? 'red' : '' }} variant="body2">
                    {error.colorCode ? 'Farbe muss festgelegt werden' : 'Farbe festlegen'}
                  </Typography>
                  <ColorPicker error={error.colorCode} size={30} color={colorCode} setColor={setColorCode} />
                </div>
              </div>
              <Divider sx={{ marginTop: '40px', marginBottom: '40px' }} />
              <DataFormOptions
                error={error}
                notificationEmail={notificationEmail}
                setNotificationEmail={setNotificationEmail}
                phoneVerification={phoneVerification}
                setPhoneVerification={setPhoneVerification}
                vehicleClasses={vehicleClasses}
                payoutModes={payoutModes}
                handleVehicleClassChange={handleVehicleClassChange}
                handlePayoutModeChange={handlePayoutModeChange}
                onlySecureIntegration={onlySecureIntegration}
                handleOnlySecureIntegrationChange={handleOnlySecureIntegrationChange}
                showFooter={showFooter}
                showHeader={showHeader}
                handleShowFooterChange={handleShowFooterChange}
                handleShowHeaderChange={handleShowHeaderChange}
                setMultipleVehicles={setMultipleVehicles}
                multipleVehicles={multipleVehicles}
                setShowCharity={setShowCharity}
                setShowHelp={setShowHelp}
                setNoVerificationEmail={setNoVerificationEmail}
                setEqualPayout={setEqualPayout}
                setShowIntropage={setShowIntropage}
                showCharity={showCharity}
                showHelp={showHelp}
                noVerificationEmail={noVerificationEmail}
                equalPayout={equalPayout}
                deactivated={deactivated}
                setDeactivated={setDeactivated}
                showIntropage={showIntropage}
              />
              {/* <TextField label="Child" name="children[0]" fullWidth />
            <TextField label="Child Partner" name="childrenPartner[0]" fullWidth /> */}
              <Divider sx={{ marginTop: '40px', marginBottom: '40px' }} />
              <BankDataForm
                error={error}
                taxId={taxId}
                setTaxId={setTaxId}
                iban={iban}
                handleIbanChange={handleIbanChange}
                paymentReceiver={paymentReceiver}
                setPaymentReceiver={setPaymentReceiver}
                creditorNumber={creditorNumber}
                setCreditorNumber={setCreditorNumber}
              />
              <Divider sx={{ marginTop: '40px', marginBottom: '40px' }} />
              <PayoutForm
                error={error}
                setM1={setM1}
                m1={m1}
                lxe={lxe}
                setLxe={setLxe}
                n1={n1}
                setN1={setN1}
                n2={n2}
                setN2={setN2}
                n3={n3}
                setN3={setN3}
                m3={m3}
                setM3={setM3}
                setM1NotInstant={setM1NotInstant}
                m1NotInstant={m1NotInstant}
                lxeNotInstant={lxeNotInstant}
                setLxeNotInstant={setLxeNotInstant}
                n1NotInstant={n1NotInstant}
                setN1NotInstant={setN1NotInstant}
                n2NotInstant={n2NotInstant}
                setN2NotInstant={setN2NotInstant}
                n3NotInstant={n3NotInstant}
                setN3NotInstant={setN3NotInstant}
                m3NotInstant={m3NotInstant}
                setM3NotInstant={setM3NotInstant}
              />
              <Button onClick={handleSubmit} sx={{ marginTop: '20px' }} variant="contained" color="primary">
                {partner ? 'speichern' : 'Partner anlegen'}
              </Button>
            </form>
          </Box>
        </>
      </Card>
    </Box>
  );
};

//
export default CompletePartner;
