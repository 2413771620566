export default function Modal() {
  return {
    MuiModal: {
      styleOverrides: {
        root: {
          width: 'auto',
        },
      },
    },
  };
}
