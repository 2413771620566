import { Button, Card, Typography } from '@mui/material';
import { GridRemoveIcon } from '@mui/x-data-grid';
import './index.css';

export const ChildContainer = ({ partner, setChildPartners }) => {
  return (
    <Card
      sx={{
        paddingLeft: '20px',
        paddingRight: '10px',
        paddingBottom: '5px',
        paddingTop: '5px',
        width: 'max-content',
        display: 'flex',
        gap: '5px',
        alignItems: 'center',
      }}
    >
      <Typography variant="h7">{partner}</Typography>
      <Button
        sx={{ padding: '1px' }}
        onClick={() => {
          setChildPartners((prev) => prev.filter((child) => child !== partner));
        }}
      >
        <GridRemoveIcon />
      </Button>
    </Card>
  );
};
