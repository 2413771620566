import { isValidIBAN } from 'ibantools';

export function validateForm(data) {
  const validationRules = {
    name: (value) => typeof value === 'string' && value.trim() !== '',
    email: (value) => /\S+@\S+\.\S+/.test(value),
    m1: (value) => String(value).trim() !== '' && String(value).trim() !== 'NaN',
    n1: (value) => String(value).trim() !== '' && String(value).trim() !== 'NaN',
    n2: (value) => String(value).trim() !== '' && String(value).trim() !== 'NaN',
    n3: (value) => String(value).trim() !== '' && String(value).trim() !== 'NaN',
    lxe: (value) => String(value).trim() !== '' && String(value).trim() !== 'NaN',
    m3: (value) => String(value).trim() !== '' && String(value).trim() !== 'NaN',
    m1NotInstant: (value) => String(value).trim() !== '' && String(value).trim() !== 'NaN',
    n1NotInstant: (value) => String(value).trim() !== '' && String(value).trim() !== 'NaN',
    n2NotInstant: (value) => String(value).trim() !== '' && String(value).trim() !== 'NaN',
    n3NotInstant: (value) => String(value).trim() !== '' && String(value).trim() !== 'NaN',
    lxeNotInstant: (value) => String(value).trim() !== '' && String(value).trim() !== 'NaN',
    m3NotInstant: (value) => String(value).trim() !== '' && String(value).trim() !== 'NaN',
    taxId: (value) => typeof value === 'string' && value.trim() !== '',
    paymentReceiver: (value) => typeof value === 'string' && value.trim() !== '',
    iban: (value) => isValidIBAN(value.replace(/ /g, '')),
    colorCode: (value) => typeof value === 'string' && /^#([0-9a-f]{3}){1,2}$/i.test(value),
    houseNumber: (value) => typeof value === 'string' && value.trim() !== '',
    street: (value) => typeof value === 'string' && value.trim() !== '',
    companyName: (value) => typeof value === 'string' && value.trim() !== '',
    zip: (value) => typeof value === 'string' && /^[0-9]{5}$/.test(value),
    city: (value) => typeof value === 'string' && value.trim() !== '',
    domain: (value) => typeof value === 'string' && value.trim() !== '',
    phone: (value) => typeof value === 'string' && value.trim() !== '',
    vehicleClasses: (value) => Object.values(value).includes(true),
    payoutModes: (value) => Object.values(value).includes(true),
    onlySecureIntegration: (value) => typeof value === 'boolean',
    showFooter: (value) => typeof value === 'boolean',
    showHeader: (value) => typeof value === 'boolean',
    phoneVerification: (value) => typeof value === 'boolean',
    multipleVehicles: (value) => typeof value === 'boolean',
    notificationEmail: (value) => /\S+@\S+\.\S+/.test(value),
    childPartners: (value) => Array.isArray(value),
    creditorNumber: () => true,
    platformPartner: (value) => typeof value === 'string' && value.trim() !== '',
    submissionPartner: (value) => typeof value === 'string' && value.trim() !== '',
    logos: (value) => value === 0,
    emailSignature: () => true,
  };

  let success = true;
  const validation = {};

  Object.keys(data).forEach((key) => {
    if (!validationRules[key](data[key])) {
      success = false;
    }

    validation[key] = !validationRules[key](data[key]);
  });

  validation['success'] = success;

  return validation;
}
