import { useState, useEffect } from 'react';
// @mui
import { Container, Box, Typography, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTheme } from '@mui/material/styles';

import axios from 'src/utils/axios';

// hooks
import useSettings from 'src/hooks/useSettings';
// components

import Page from 'src/components/Page';

// ----------------------------------------------------------------------

export default function VerifySubscription() {
  const { enqueueSnackbar } = useSnackbar();
  const { verifyToken = '' } = useParams();
  const theme = useTheme();
  const [tokenVerified, setTokenVerified] = useState(false);
  const [successVerified, setSuccessVerified] = useState(false);

  const { themeStretch } = useSettings();

  useEffect(() => {
    handleVerifySubscription(verifyToken);
  }, [verifyToken]);

  const handleVerifySubscription = async (verifyToken) => {
    try {
      const endpoint = `/v1/subscription/verify/${verifyToken}`;
      const response = await axios.get(endpoint);

      if (response.data && response.data.success) {
        setSuccessVerified(true);
      } else {
        setSuccessVerified(false);
      }
      setTokenVerified(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Page
      title="Verifizieren Sie Ihre Email-Adresse"
      sx={{
        '& .MuiFormControl-fullWidth': { marginBottom: 1 },
        display: 'flex',
        minHeight: '100vh',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        mt: 12,
      }}
    >
      <Container maxWidth={themeStretch ? false : 'xl'}>
        {!tokenVerified || !verifyToken ? (
          <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box sx={{ margin: 'auto', textAlign: 'center' }}>
              {successVerified ? (
                <Box>
                  {' '}
                  <CheckCircleOutlineIcon color="success" sx={{ fontSize: '45px', display: 'block', margin: 'auto' }} />
                  <Typography sx={{ marginTop: '10px', fontWeight: '700' }}>
                    Vielen Dank für die Bestätigung Ihrer Email-Adresse!
                  </Typography>
                  {/* <Typography sx={{ marginTop: '10px' }}>
                    Wir werden Sie über steigende Preise der THG-Quotenprämie informieren.
                  </Typography> */}
                </Box>
              ) : (
                <Box>
                  {' '}
                  <ErrorOutlineIcon color="error" sx={{ fontSize: '45px', display: 'block', margin: 'auto' }} />
                  <Typography sx={{ marginTop: '10px', fontWeight: '700' }}>
                    Es ist ein Fehler aufgetreten. Bitte wenden Sie sich an unseren Support unter{' '}
                    <a style={{ color: theme.palette.primary.main }} href="mailto:info@thg-quotenportal.de">
                      info@thg-quotenportal.de
                    </a>
                  </Typography>
                </Box>
              )}
            </Box>
          </>
        )}
      </Container>
    </Page>
  );
}
