import { TextField, Typography } from '@mui/material';

export const ContactForm = ({
  error,
  setName,
  name,
  handleEmailChange,
  email,
  phone,
  setPhone,
  setStreet,
  street,
  setCompanyName,
  companyName,
  houseNumber,
  setHouseNumber,
  zip,
  setZip,
  city,
  setCity,
  domain,
  setDomain,
  partner,
}) => (
  <>
    <Typography variant="h6">Kontaktdaten</Typography>
    <TextField
      disabled={!!partner}
      error={error.name}
      onChange={(e) => {
        setName(e.target.value);
      }}
      label="Name"
      name="name"
      value={name}
      fullWidth
    />
    <TextField
      error={error.email}
      inputProps={{
        inputMode: 'email',
      }}
      onChange={handleEmailChange}
      value={email}
      label="Email"
      name="email"
      fullWidth
    />

    <TextField
      error={error.phone}
      onChange={(e) => {
        setPhone(e.target.value);
      }}
      value={phone}
      label="Telefon"
      name="phone"
      fullWidth
    />
    <TextField
      error={error.companyName}
      onChange={(e) => {
        setCompanyName(e.target.value);
      }}
      value={companyName}
      label="Firmenname"
      name="addressData.companyName"
      fullWidth
    />
    <TextField
      error={error.street}
      onChange={(e) => {
        setStreet(e.target.value);
      }}
      value={street}
      label="Strasse"
      name="addressData.street"
      fullWidth
    />
    <TextField
      error={error.houseNumber}
      onChange={(e) => {
        setHouseNumber(e.target.value);
      }}
      value={houseNumber}
      label="Hausnummer"
      type="number"
      name="addressData.houseNumber"
      fullWidth
    />
    <TextField
      error={error.zip}
      onChange={(e) => {
        setZip(e.target.value);
      }}
      value={zip}
      label="Postleitzahl"
      type="number"
      name="addressData.zip"
      fullWidth
    />
    <TextField
      error={error.city}
      onChange={(e) => {
        setCity(e.target.value);
      }}
      value={city}
      label="Stadt"
      name="addressData.city"
      fullWidth
    />
    <TextField
      error={error.domain}
      value={domain}
      onChange={(e) => {
        setDomain(e.target.value);
      }}
      label="Domain"
      name="domain"
      fullWidth
    />
  </>
);
