
import { io } from 'socket.io-client';
import { createContext} from 'react';
import { HOST_API } from "../config";

// ----------------------------------------------------------------------
 



const SocketContext = createContext({
  socket:null
});

// ----------------------------------------------------------------------



function SocketProvider({ children }) {

    const socket = io(HOST_API,{transports: ['websocket'], upgrade: false, secure: true});
    socket.on('connect', () => console.log("connected to websocket"));
    socket.on('connect_error', () => {
      setTimeout(() => socket.connect(), 5000);
    });
  return (
    <SocketContext.Provider
      value={{
        socket
      }}
    >
      {children}
    </SocketContext.Provider>
  );
}

export { SocketContext, SocketProvider };
