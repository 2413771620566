// @mui
import { Accordion, Typography, AccordionSummary, AccordionDetails } from '@mui/material';
// components
import parse from 'html-react-parser';

import Iconify from 'src/components/Iconify';

// ----------------------------------------------------------------------

export default function FaqsList({ faqData }) {
  return (
    <>
      {faqData.map((accordion) => (
        <Accordion key={accordion.value}>
          <AccordionSummary expandIcon={<Iconify icon={'eva:arrow-ios-downward-fill'} width={20} height={20} />}>
            <Typography variant="subtitle1">{accordion.heading}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{parse(accordion.detail)}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
}
