import { Outlet } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import Logo from 'src/components/thgqp/Logo';
import Footer from 'src/components/thgqp/FooterStaticPages';

// ----------------------------------------------------------------------
const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: '16px 12px',
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: '30px',
  },
}));

// ----------------------------------------------------------------------

export default function ThgqpLayout() {
  return (
    <Box
      sx={{
        '& .MuiFormControl-fullWidth': { marginBottom: 1 },
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        background: '#FFF',
      }}
    >
      <HeaderStyle>
        <Logo statusPage />
      </HeaderStyle>
      <Outlet />

      <Footer />
    </Box>
  );
}
