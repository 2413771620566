import { Checkbox, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';

export const SubmissionPartnerSelection = ({ setState, state, error }) => (
  <div>
    <FormControl fullWidth>
      <InputLabel>Quotenkäufer</InputLabel>
      <Select
        error={error}
        value={state}
        label="Submission Partner"
        onChange={(e) => {
          setState(e.target.value);
        }}
      >
        <MenuItem value={'mobene'}>mobene</MenuItem>
        <MenuItem value={'Juicify'}>Juicify</MenuItem>
      </Select>
    </FormControl>
  </div>
);
